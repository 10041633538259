export default {
    namespaced: true,
    state: {
        points: [],
    },
    mutations: {
        getPoints(state, payload) {
            state.points = payload;
        },
    },
    actions: {
        getPoints: function ({ commit }) {
            const points = [
                {
                    id: 1,
                    name: {
                        pl: "Zrównoważona motoryzacja",
                        en: "Sustainable motorization",
                    },
                    nameFull: {
                        pl: 'Zrównoważona <span class="color-main">motoryzacja</span>',
                        en: 'Sustainable <span class="color-main">motorization</span>',
                    },
                    banner: "realizations/1/banner.jpg",
                    video: "https://dev.touchpointincentive.pl/assets/app/media/norway-sustainable-motorization.mp4",
                    videoPoster: null,
                    logo: "realizations/1/logo.svg",
                    thumb: "realizations/1/thumb.jpg",
                    slug: "zrownowazona-motoryzacja",
                    place: {
                        pl: "Norwegia",
                        en: "Norway",
                    },
                    brand: "Grupa Plichta",
                    participants: {
                        pl: "Zarząd i&nbsp;dyrektorzy sieci dealerskiej",
                        en: "Board and&nbsp;directors of the dealer network",
                    },
                    goal: {
                        pl: "Promowanie elektromobilności",
                        en: "Promoting electro-mobility",
                    },
                    description: {
                        pl: "Za cel podróży wybraliśmy słynącą z&nbsp;OZE i&nbsp;aut elektrycznych Norwegię, gdzie uczestnicy odbyli szkolenie dot. elektromobilności. Niskoemisyjność towarzyszyła nam również w&nbsp;odpoczynku. Zaplanowaliśmy relaks w&nbsp;dryfującej, opalanej drewnem saunie i&nbsp;rejs po fiordzie, a&nbsp;po lądzie przemieszczaliśmy się rowerami górskimi i&nbsp;kolejką. Całość zwieńczyliśmy uroczystą kolacją wzorowaną na rozdaniu pokojowych nagród Nobla w&nbsp;hotelu Grand.",
                        en: "For our destination, we’ve chosen Norway, which is famous for RES and electric cars, where our participants have completed training on electro-mobility. Low emission has been a part of our leisure as well. We’ve planned a relaxing session in a drifting, wood-fired sauna and a boat trip to the fjord. While on land, we travelled using mountain bikes and railways. Everything was finished with a dinner inspired by the Nobel Peace Awards ceremony in the Grand Hotel.",
                    },
                    left: "49%",
                    top: "19%",
                    boxXDirection: "left",
                    boxYDirection: "bottom",
                    gallery: {
                        1: "realizations/1/gallery-1.jpg",
                        2: "realizations/1/gallery-2.jpg",
                        3: "realizations/1/gallery-3.jpg",
                        4: "realizations/1/gallery-4.jpg",
                        5: "realizations/1/gallery-5.jpg",
                    },
                },
                // {
                //     id: 1,
                //     name: {
                //         pl: 'Biznes w&nbsp;operze',
                //         en: 'Business in&nbsp;the&nbsp;Opera',
                //     },
                //     nameFull: {
                //         pl: 'Biznes <span class="color-main">w&nbsp;Operze</span>',
                //         en: 'Business <span class="color-main">in&nbsp;the&nbsp;Opera</span>',
                //     },
                //     banner: 'realizations/1/banner.jpg',
                //     logo: 'realizations/1/logo.svg',
                //     thumb: 'realizations/1/thumb.jpg',
                //     slug: 'biznes-w-operze',
                //     place: {
                //         pl: 'Norwegia',
                //         en: 'Norway',
                //     },
                //     brand: 'Rockwool',
                //     participants: {
                //         pl: 'Dystrybutorzy materiałów budowlanych',
                //         en: 'Building materials distributors',
                //     },
                //     goal: {
                //         pl: 'Inspiracja do wspólnego rozwoju biznesu',
                //         en: 'Inspiration for joint business development',
                //     },
                //     description: {
                //         pl: 'Gdzie zorganizować biznesowe spotkanie dla producenta wełny skalnej? Tam, gdzie warunki pozwolą się wykazać produktowi! Zaprosiliśmy więc dystrybutorów materiałów budowlanych do Norwegii, a&nbsp;dokładniej do opery w&nbsp;Oslo, której izolacja została wykonana z&nbsp;wykorzystaniem wełny Rockwool. To właśnie tam Jacek Santorski poprowadził dla uczestników wyjazdu wykład pt. „Skuteczny lider”.',
                //         en: 'What’s the&nbsp;best place to&nbsp;hold a&nbsp;business meeting with a&nbsp;mineral wool producer? One where the product can shine! That’s why we’ve invited a&nbsp;group of&nbsp;building materials distributors to&nbsp;Norway to&nbsp;see the&nbsp;Oslo Opera House, which has been built with the&nbsp;use of&nbsp;wool from Rockwool. This was the&nbsp;best place for Jacek Santorski to&nbsp;hold a&nbsp;“Successful leader” lecture for&nbsp;the&nbsp;participants.',
                //     },
                //     left: '49%',
                //     top: '19%',
                //     boxXDirection: 'left',
                //     boxYDirection: 'bottom',
                //     gallery:{
                //         1: 'realizations/1/gallery-1.jpg',
                //         2: 'realizations/1/gallery-2.jpg',
                //         3: 'realizations/1/gallery-3.jpg',
                //         4: 'realizations/1/gallery-4.jpg',
                //         5: 'realizations/1/gallery-5.jpg',
                //     }
                // },
                {
                    id: 2,
                    name: {
                        pl: "Amerykański sen",
                        en: "The American dream",
                    },
                    nameFull: {
                        pl: 'Amerykański <span class="color-main">sen</span>',
                        en: 'The American <span class="color-main">dream</span>',
                    },
                    banner: "realizations/2/banner.jpg",
                    logo: "realizations/2/logo.svg",
                    thumb: "realizations/2/thumb.jpg",
                    slug: "amerykanski-sen",
                    place: {
                        pl: "USA",
                        en: "USA",
                    },
                    brand: "3M",
                    participants: {
                        pl: "Przedstawiciele handlowi",
                        en: "Sales representatives",
                    },
                    goal: {
                        pl: "Motywacja do zwiększania sprzedaży",
                        en: "Motivation to increase sales",
                    },
                    description: {
                        pl: "Razem z amerykańskim koncernem spełniliśmy amerykański sen przedstawicieli handlowych. Ci z&nbsp;nich, którzy osiągnęli najlepsze wyniki w&nbsp;sprzedaży produktów 3M polecieli na pełną emocji wyprawę do Vegas i&nbsp;Wielkiego Kanionu. Przez całą podróż towarzyszył im mistrz kierownicy Krzysztof Hołowczyc.",
                        en: "Under a&nbsp;collaboration with an&nbsp;American concern, we’ve managed to&nbsp;fulfill the&nbsp;American dream of&nbsp;a&nbsp;group of&nbsp;sales representatives. Those, who’ve managed to&nbsp;achieve the&nbsp;best results in&nbsp;3M products’ sales, have flown on&nbsp;a&nbsp;trip filled with emotions to&nbsp;Vegas and&nbsp;the&nbsp;Grand Canyon. They have been accompanied by&nbsp;a&nbsp;champion driver - Krzysztof Hołowczyc.",
                    },
                    left: "18%",
                    top: "45%",
                    boxXDirection: "right",
                    boxYDirection: "bottom",
                    gallery: {
                        1: "realizations/2/gallery-1.jpg",
                        2: "realizations/2/gallery-2.jpg",
                        3: "realizations/2/gallery-3.jpg",
                        4: "realizations/2/gallery-4.jpg",
                        5: "realizations/2/gallery-5.jpg",
                    },
                },
                {
                    id: 3,
                    name: {
                        pl: "Podróż dla zmysłów",
                        en: "A journey for&nbsp;your senses",
                    },
                    nameFull: {
                        pl: 'Podróż dla <span class="color-main">zmysłów</span>',
                        en: 'A&nbsp;journey for&nbsp;your <span class="color-main">senses</span>',
                    },
                    banner: "realizations/3/banner.jpg",
                    logo: "realizations/3/logo.svg",
                    thumb: "realizations/3/thumb.jpg",
                    slug: "podroz-dla-zmyslow",
                    place: {
                        pl: "Toskania",
                        en: "Tuscany",
                    },
                    brand: "Whirlpool",
                    participants: {
                        pl: "Właściciele studiów kuchennych",
                        en: "Owners of&nbsp;kitchen studios",
                    },
                    goal: {
                        pl: "Budowa relacji z&nbsp;najlepszymi partnerami biznesowymi",
                        en: "Building relations with the&nbsp;best business partners",
                    },
                    description: {
                        pl: "Toskania zachwyca smakiem lokalnej kuchni, zapachem wszechobecnej winorośli i&nbsp;zapierającymi dech w&nbsp;piersiach widokami. Na te same zmysły oddziałują doskonale przygotowane dania, dlatego to właśnie tam zabraliśmy właścicieli studiów kuchennych sprzedających sprzęt Whirlpool z&nbsp;technologią 6.&nbsp;Zmysł. Przez Toskanię oprowadziliśmy ich my, ale od kuchni zaprezentował ją nasz ekspert – Grzegorz Łapanowski.",
                        en: "Tuscany is loved for&nbsp;the&nbsp;taste of&nbsp;local cuisine, scent of&nbsp;vines, and&nbsp;breathtaking views. The&nbsp;same senses are being stimulated by&nbsp;food, which is why we’ve decided to&nbsp;take the&nbsp;owners of&nbsp;kitchen studios selling Whirlpool products with a&nbsp;6th sense technology for&nbsp;a&nbsp;trip to&nbsp;that region. We have shown them Tuscany, but the&nbsp;cuisine has been presented by&nbsp;our&nbsp;expert - Grzegorz Łapanowski.",
                    },
                    left: "49%",
                    top: "35%",
                    boxXDirection: "left",
                    boxYDirection: "bottom",
                    gallery: {
                        1: "realizations/3/gallery-1.jpg",
                        2: "realizations/3/gallery-2.jpg",
                        3: "realizations/3/gallery-3.jpg",
                        4: "realizations/3/gallery-4.jpg",
                        5: "realizations/3/gallery-5.jpg",
                    },
                },
                // {
                //     id: 4,
                //     name: 'Zasłużony wypoczynek',
                //     nameFull: 'Zasłużony <span class="color-main">wypoczynek</span>',
                //     banner: 'realization_example.jpg',
                //     logo: '',
                //     thumb: 'realizations/4/thumb.jpg',
                //     slug: 'zasluzony-wypoczynek',
                //     place: 'Cypr',
                //     brand: 'Upfield',
                //     participants: 'Handlowcy',
                //     goal: 'Motywacja do osiągania najlepszych wyników',
                //     description: '',
                //     left: '54%',
                //     top: '41%',
                //     boxXDirection: 'left',
                //     boxYDirection: 'bottom',
                //     gallery:{
                //         1: 'gallery-1.jpg',
                //         2: 'gallery-2.jpg',
                //         3: 'gallery-3.jpg',
                //         4: 'gallery-4.jpg',
                //         5: 'gallery-5.jpg',
                //     }
                // },
                {
                    id: 5,
                    name: {
                        pl: "Uprawy od kuchni",
                        en: "From field to&nbsp;plate",
                    },
                    nameFull: {
                        pl: 'Uprawy <span class="color-main">od kuchni</span>',
                        en: 'From field <span class="color-main">to&nbsp;plate</span>',
                    },
                    banner: "realizations/5/banner.jpg",
                    logo: "realizations/5/logo.svg",
                    thumb: "realizations/5/thumb.jpg",
                    slug: "uprawy-od-kuchni",
                    place: {
                        pl: "Gruzja",
                        en: "Georgia",
                    },
                    brand: "Sumi Agro Poland",
                    participants: {
                        pl: "Właściciele gospodarstw rolnych",
                        en: "Owners of&nbsp;agricultural enterprises",
                    },
                    goal: {
                        pl: "Umocnienie relacji biznesowych",
                        en: "Strengthening the&nbsp;business relations",
                    },
                    description: {
                        pl: "Gruzja to kraj w&nbsp;którym rolnictwo, uprawa zbóż i&nbsp;winorośli zajmują ważne miejsce, a&nbsp;zróżnicowana kuchnia jest zaliczana do jednych z&nbsp;najbardziej naturalnych na świecie. Wspólna eksploracja pięknego kraju i&nbsp;warsztaty kulinarne z&nbsp;Karolem Okrasą umocniły biznesowe relacje z&nbsp;właścicielami największych gospodarstw rolnych.",
                        en: "Georgia is a&nbsp;country in which agriculture and cultivation of&nbsp;crops and&nbsp;vines are very important, and its diverse cuisine is&nbsp;considered to&nbsp;be one of&nbsp;the&nbsp;most natural in&nbsp;the&nbsp;world. Exploring this country together and&nbsp;cooking class with Karol Okrasa has strengthened the&nbsp;business relations with the&nbsp;owners of&nbsp;the&nbsp;largest agricultural enterprises.",
                    },
                    left: "58%",
                    top: "36%",
                    boxXDirection: "left",
                    boxYDirection: "bottom",
                    gallery: {
                        1: "realizations/5/gallery-1.jpg",
                        2: "realizations/5/gallery-2.jpg",
                        3: "realizations/5/gallery-3.jpg",
                        4: "realizations/5/gallery-4.jpg",
                        5: "realizations/5/gallery-5.jpg",
                    },
                },
                {
                    id: 6,
                    name: {
                        pl: "Królestwo smaków",
                        en: "The kingdom of&nbsp;taste",
                    },
                    nameFull: {
                        pl: 'Królestwo <span class="color-main">smaków</span>',
                        en: 'The kingdom of&nbsp;<span class="color-main">taste</span>',
                    },
                    banner: "realizations/6/banner.jpg",
                    logo: "realizations/6/logo.svg",
                    thumb: "realizations/6/thumb.jpg",
                    slug: "krolestwo-smakow",
                    place: {
                        pl: "Maroko",
                        en: "Morocco",
                    },
                    brand: "Whirlpool",
                    participants: {
                        pl: "Właściciele studiów kuchennych",
                        en: "Owners of&nbsp;kitchen studios",
                    },
                    goal: {
                        pl: "Wznowienie relacji z&nbsp;najlepszym partnerami biznesowymi",
                        en: "Building relations with the best business partners",
                    },
                    description: {
                        pl: "Marokańska kuchnia oddziałuje na wszystkie zmysły. Nawet na 6-zmysł od lat wpisany w&nbsp;komunikację marki Whirlpool. Dlatego to właśnie tam zabraliśmy właścicieli studiów kuchennych na&nbsp;inspirujący tydzień kulinarny.",
                        en: "The Morrocan cuisine stimulates all of&nbsp;your senses. Even the&nbsp;6th sense that’s been a&nbsp;part of&nbsp;Whirlpool’s communication for&nbsp;years. Which is why we’ve decided to&nbsp;take the&nbsp;owners of&nbsp;kitchen studios for&nbsp;an&nbsp;inspiring culinary week to&nbsp;that country.",
                    },
                    left: "43%",
                    top: "44%",
                    boxXDirection: "left",
                    boxYDirection: "bottom",
                    gallery: {
                        1: "realizations/6/gallery-1.jpg",
                        2: "realizations/6/gallery-2.jpg",
                        3: "realizations/6/gallery-3.jpg",
                        4: "realizations/6/gallery-4.jpg",
                        5: "realizations/6/gallery-5.jpg",
                    },
                },
                {
                    id: 7,
                    name: {
                        pl: "Bezcenna opowieść",
                        en: "A priceless tale",
                    },
                    nameFull: {
                        pl: 'Bezcenna <span class="color-main">opowieść</span>',
                        en: 'A priceless <span class="color-main">tale</span>',
                    },
                    banner: "realizations/7/banner.jpg",
                    logo: "realizations/7/logo.svg",
                    thumb: "realizations/7/thumb.jpg",
                    slug: "bezcenna-opowiesc",
                    place: {
                        pl: "Andaluzja",
                        en: "Andalusia",
                    },
                    brand: "Mastercard",
                    participants: {
                        pl: "Doradcy Bankowi",
                        en: "Bank Advisers",
                    },
                    goal: {
                        pl: "Motywacja do edukacji",
                        en: "Educational motivation",
                    },
                    description: {
                        pl: "Przez blisko rok doradcy bankowi brali udział w&nbsp;edukacyjnej akcji pełnej zagadek. Jej fabuła zaprowadziła ich do Andaluzji. Jako że w&nbsp;centrum działań Mastercard® od zawsze była bezcenność, daliśmy doradcom możliwość odwiedzenia miejsca, o&nbsp;którym tyle czytali i&nbsp;odkrywania jego sekretów samodzielnie.",
                        en: "For nearly a&nbsp;year, bank advisers had been participating in&nbsp;an&nbsp;educational action filled with riddles. Its&nbsp;plot had led them to&nbsp;Andalusia. Since pricelessness has always been the&nbsp;centre of&nbsp;all Mastercard® activities, we had decided to&nbsp;give the&nbsp;advisers an&nbsp;opportunity to&nbsp;visit the&nbsp;place that they’ve read so much about and&nbsp;uncover its secrets themselves.",
                    },
                    left: "44%",
                    top: "36%",
                    boxXDirection: "left",
                    boxYDirection: "bottom",
                    gallery: {
                        1: "realizations/7/gallery-1.jpg",
                        2: "realizations/7/gallery-2.jpg",
                        3: "realizations/7/gallery-3.jpg",
                        4: "realizations/7/gallery-4.jpg",
                        5: "realizations/7/gallery-5.jpg",
                    },
                },
                {
                    id: 8,
                    name: {
                        pl: "Mozaika przygód",
                        en: "A mosaic of&nbsp;adventures",
                    },
                    nameFull: {
                        pl: 'Mozaika <span class="color-main">przygód</span>',
                        en: 'A mosaic <span class="color-main">of&nbsp;adventures</span>',
                    },
                    banner: "realizations/8/banner.jpg",
                    logo: "realizations/8/logo.svg",
                    thumb: "realizations/8/thumb.jpg",
                    slug: "mozaika-przygod",
                    place: {
                        pl: "Laponia",
                        en: "Lapland",
                    },
                    brand: "Rovese",
                    participants: {
                        pl: "Sprzedawcy salonów łazienkowych",
                        en: "Salespeople from bathroom stores",
                    },
                    goal: {
                        pl: "Motywacja do sprzedaży produktów marek Cersanit i&nbsp;Opoczno",
                        en: "Motivation to&nbsp;sell Opoczno and&nbsp;Cersanit products",
                    },
                    description: {
                        pl: "Zwycięzcy rankingu sprzedaży w&nbsp;programie Mozaika Przygód mieli możliwość przeżyć prawdziwą przygodę w&nbsp;śnieżnej Finlandii. Zainspirowani nazwą programu stworzyliśmy 4-dniową wyprawę do&nbsp;Ravaniemi, podczas której uczestnicy sprawdzili się w&nbsp;4&nbsp;wyzwaniach: jeździe na&nbsp;skuterach śnieżnych, quadach, powożeniu psimi zaprzęgami i&nbsp;kontrolowaniu auta na oblodzonym terenie.",
                        en: "The winners of&nbsp;the&nbsp;sales rankings in&nbsp;the&nbsp;Mosaic of&nbsp;Adventures program had the&nbsp;chance to&nbsp;experience a&nbsp;true adventure in&nbsp;snowy Finland. Inspired by&nbsp;the&nbsp;name of&nbsp;the&nbsp;program, we’ve organised a&nbsp;4-day trip to&nbsp;Ravaniemi, during which the&nbsp;participants have been tested in&nbsp;4&nbsp;challenges: driving snowmobiles, ATV-driving, driving dog-sleds, and&nbsp;controlling a&nbsp;car on &nbsp;n&nbsp;icy surface.",
                    },
                    left: "54%",
                    top: "16%",
                    boxXDirection: "left",
                    boxYDirection: "bottom",
                    gallery: {
                        1: "realizations/8/gallery-1.jpg",
                        2: "realizations/8/gallery-2.jpg",
                        3: "realizations/8/gallery-3.jpg",
                        4: "realizations/8/gallery-4.jpg",
                        5: "realizations/8/gallery-5.jpg",
                    },
                },
                {
                    id: 9,
                    name: {
                        pl: "W sercu innowacji",
                        en: "At the heart of&nbsp;innovation",
                    },
                    nameFull: {
                        pl: 'W&nbsp;sercu <span class="color-main">innowacji</span>',
                        en: 'At the heart of&nbsp;<span class="color-main">innovation</span>',
                    },
                    banner: "realizations/9/banner.jpg",
                    logo: "realizations/9/logo.svg",
                    thumb: "realizations/9/thumb.jpg",
                    slug: "w-sercu-innowacji",
                    place: {
                        pl: "Japonia",
                        en: "Japan",
                    },
                    brand: "Mercedes-Benz",
                    participants: {
                        pl: "Sprzedawcy i&nbsp;serwisanci samochodów ciężarowych",
                        en: "Salespeople and&nbsp;servicepeople working with trucks",
                    },
                    goal: {
                        pl: "Edukacja i&nbsp;motywacja uczestników programu",
                        en: "Education and motivation for&nbsp;the&nbsp;participants of&nbsp;the&nbsp;program",
                    },
                    description: {
                        pl: "Uczestnicy programu Gwiazdy Innowacji odkrywali zaawansowane technologie wykorzystane w&nbsp;samochodach ciężarowych Mercedes. Ale tylko najlepsi z&nbsp;nich mogli odbyć podróż do centrum innowacji – Japońskiej fabryki koncernu – i&nbsp;na własne oczy zobaczyć najnowsze technologie wykorzystywane w&nbsp;transporcie.",
                        en: "The participants of&nbsp;the&nbsp;Gwiazdy Innowacji (Stars of&nbsp;Innovation) program had been discovering advanced technologies used in&nbsp;Mercedes trucks. However, only the&nbsp;very best were able to&nbsp;be a&nbsp;part of&nbsp;a&nbsp;trip to&nbsp;the&nbsp;very heart of&nbsp;innovation - corporation’s facility in&nbsp;Japan - and&nbsp;see the&nbsp;latest technologies used in&nbsp;transportation with their very eyes.",
                    },
                    left: "84.5%",
                    top: "38.5%",
                    boxXDirection: "left",
                    boxYDirection: "bottom",
                    gallery: {
                        1: "realizations/9/gallery-1.jpg",
                        2: "realizations/9/gallery-2.jpg",
                        3: "realizations/9/gallery-3.jpg",
                        4: "realizations/9/gallery-4.jpg",
                        5: "realizations/9/gallery-5.jpg",
                    },
                },
                /*{
                    id: 7,
                    name: '',
                    nameFull: '<span class="color-main"></span>',
                    banner: 'realizations/7/banner.jpg',
                    logo: 'realizations/7/logo.svg',
                    thumb: 'realizations/7/thumb.jpg',
                    slug: '',
                    place: '',
                    brand: '',
                    participants: '',
                    goal: '',
                    description: '',
                    left: '44%',
                    top: '36%',
                    boxXDirection: 'left',
                    boxYDirection: 'bottom',
                    gallery:{
                        1: 'realizations/7/gallery-1.jpg',
                        2: 'realizations/7/gallery-2.jpg',
                        3: 'realizations/7/gallery-3.jpg',
                        4: 'realizations/7/gallery-4.jpg',
                        5: 'realizations/7/gallery-5.jpg',
                    }
                },*/
                {
                    id: 11,
                    name: {
                        pl: "Mistrzowski wyjazd",
                        en: "A Champion’s trip",
                    },
                    nameFull: {
                        pl: 'Mistrzowski <span class="color-main">wyjazd</span>',
                        en: 'A Champion’s  <span class="color-main">trip</span>',
                    },
                    banner: "realizations/11/banner.jpg",
                    video: "https://dev.touchpointincentive.pl/assets/app/media/zea.mp4",
                    videoPoster: null,
                    logo: "realizations/11/logo.svg",
                    thumb: "realizations/11/thumb.jpg",
                    slug: "mistrzowski-wyjazd",
                    place: {
                        pl: "Zjednoczone Emiraty <br/>" + "Arabskie + Katar",
                        en: "United Arab <br/>" + "Emirates + Qatar",
                    },
                    brand: "Grupa Żywiec",
                    participants: {
                        pl: "Siły sprzedaży, klienci VIP, Partnerzy Handlowi",
                        en: "Salesforce, VIP clients, Sales Partners",
                    },
                    goal: {
                        pl: "Wynagrodzenie sprzedaży i&nbsp;współpracy",
                        en: "Rewarding sales and partnership",
                    },
                    description: {
                        pl: "Na przestrzeni 11 dni zorganizowaliśmy wyjazdy dla 4&nbsp;różnych grup naszego Klienta. Widzieliśmy mundialowy mecz Polska – Arabia Saudyjska, byliśmy na Grand Prix F1 w&nbsp;Abu Zabi i&nbsp;bawiliśmy się na koncercie Kendricka Lamara. Zorganizowaliśmy dune bashing, czyli jazdę po wydmach, wynajęliśmy strefę na plaży, a&nbsp;na dodatek zwiedzaliśmy najwyższy budynek świata – Burj Khalifa. Przy okazji tej realizacji zarezerwowaliśmy ponad 700 noclegów, a&nbsp;skala tej operacji doczekała się nagrody ORYX 2022 od Emiratu Ras Al Khaimah.",
                        en: "Over the course of 11 days, we’ve organized trips for 4 different groups for our Client. We watched the World Cup game between Poland and Saudi Arabia, we’ve been to the F1 Grand Prix in Abu Dhabi, and we had fun at Kendrick Lamar’s concert. We organized dune bashing (dune riding), rented a beach area, and took a trip around the tallest building in the world – Burj Khalifa. Under this realization, we’ve booked over 700 accommodations, and the scale of the operation has been awarded the ORYX 2022 award from the Ras Al Khaimah emirate.",
                    },
                    left: "61%",
                    top: "48%",
                    boxXDirection: "left",
                    boxYDirection: "bottom",
                    gallery: {
                        1: "realizations/11/gallery-1.jpg",
                        2: "realizations/11/gallery-2.jpg",
                        3: "realizations/11/gallery-3.jpg",
                        4: "realizations/11/gallery-4.jpg",
                        5: "realizations/11/gallery-5.jpg",
                    },
                },
                {
                    id: 10,
                    name: {
                        pl: "Twój wyjazd?",
                        en: "Your trip?",
                    },
                    nameFull: {
                        pl: '<span class="color-main">Twój</span> wyjazd?',
                        en: '<span class="color-main">Your</span> trip?',
                    },
                    banner: "realization_example.jpg",
                    logo: "",
                    thumb: "",
                    slug: "kontakt",
                    place: {
                        pl: "Nowa Zelandia",
                        en: "New Zealand",
                    },
                    brand: "",
                    participants: {
                        pl: "",
                        en: "",
                    },
                    goal: {
                        pl: "",
                        en: "",
                    },
                    description: {
                        pl: "",
                        en: "",
                    },
                    short_description: {
                        pl: "Zabierz swoich partnerów biznesowych lub pracowników w&nbsp;podróż do świata twojej marki!",
                        en: "Take your business partners or&nbsp;employees on a&nbsp;journey into the&nbsp;world of&nbsp;your brand!",
                    },
                    left: "94%",
                    top: "87%",
                    boxXDirection: "left",
                    routerName: "home",
                    routerHash: "#kontakt",
                    btnText: {
                        pl: "Kontakt",
                        en: "Contact&nbsp;us",
                    },
                    boxYDirection: "top",
                },
            ];
            commit("getPoints", points);
        },
    },
    getters: {
        getPoints(state) {
            return state.points;
        },
        getPoint(state) {
            return (slug) => {
                return state.points.find((point) => point.slug == slug);
            };
        },
    },
};
