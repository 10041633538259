<template>
    <footer class="footer mb-4 text-center text-md-start">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="border-top-1"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6">
                    <p class="mb-4 pb-1">
                        <router-link class="" :to="{name: 'home'}">
                            <img class="footer__logo" src="@/img/touchpoint_logo.svg" alt="Logo"/>
                        </router-link>
                    </p>
                    <div class="d-none d-md-block">
                        <p class="mb-0">
                            {{new Date().getFullYear()}}  ©  Touchpoint Incentive
                        </p>
                        <p>
                            <a target="_blank" class="mr-4" :href="$t('footer.privacy_policy_url')">{{ $t('footer.privacy_policy') }}</a>
                            <a target="_blank" class="" :href="$t('footer.cookies_url')">{{ $t('footer.cookies') }}</a>
                        </p>
                    </div>
                </div>
                <div class="col-12 col-md-3 mb-4">
                    <p class="f24 fw300 mb-2">{{ $t('footer.address') }}</p>
                    <p>
                        ul. Truskawiecka&nbsp;1<br/>
                        60-478 Poznań
                    </p>
                </div>
                <div class="col-12 col-md-3 mb-4">
                    <p class="f24 fw300 mb-2">{{ $t('footer.social_media') }}</p>
                    <p>
                        <a class="text-decoration-none" target="_blank" href="https://www.facebook.com/profile.php?id=100084694985660">
                            <span class="w-16px">
                                <svg xmlns="http://www.w3.org/2000/svg" width="10.036" height="17" viewBox="0 0 10.036 17">
                                  <path id="facebook" d="M2.68,16V8.951H0V6.035H2.68V3.466a3.274,3.274,0,0,1,.795-2.157A4.114,4.114,0,0,1,3.789.985,3.921,3.921,0,0,1,6.439,0H9V2.678H7.264A1.4,1.4,0,0,0,5.813,4.029V6.035H8.962l-.4,2.917H5.813V16Z" transform="translate(0.5 0.5)" fill="#012169" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                                </svg>
                            </span> &nbsp;Facebook
                        </a><br/>
                        <a v-if="false" class="text-decoration-none" target="_blank" href="">
                            <span class="w-16px">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                  <g id="instagram" transform="translate(-1196 -5513)">
                                    <path id="Path_389" data-name="Path 389" d="M14.957,19.127a4.17,4.17,0,1,1,4.17-4.17,4.175,4.175,0,0,1-4.17,4.17m0-7.231a3.061,3.061,0,1,0,3.061,3.061A3.064,3.064,0,0,0,14.957,11.9" transform="translate(1189.043 5506.043)" fill="#012169"/>
                                    <path id="Path_390" data-name="Path 390" d="M12.11,16H3.89A3.9,3.9,0,0,1,0,12.11V3.89A3.894,3.894,0,0,1,3.89,0h8.22A3.894,3.894,0,0,1,16,3.89v8.22A3.894,3.894,0,0,1,12.11,16M3.89,1.308A2.585,2.585,0,0,0,1.308,3.89v8.22A2.585,2.585,0,0,0,3.89,14.692h8.22a2.585,2.585,0,0,0,2.582-2.582V3.89A2.585,2.585,0,0,0,12.11,1.308Z" transform="translate(1196 5513)" fill="#012169"/>
                                    <path id="Path_391" data-name="Path 391" d="M43.744,9.583a.8.8,0,1,1-.8-.8.8.8,0,0,1,.8.8" transform="translate(1165.903 5506.729)" fill="#012169"/>
                                    <path id="Path_392" data-name="Path 392" d="M42.8,10.307a.858.858,0,1,1,.858-.858.859.859,0,0,1-.858.858m0-1.608a.749.749,0,1,0,.749.749A.75.75,0,0,0,42.8,8.7" transform="translate(1166.039 5506.864)" fill="#012169"/>
                                  </g>
                                </svg>
                            </span> &nbsp;Instagram
                        </a>
                        <a class="text-decoration-none" target="_blank" href="https://www.linkedin.com/company/touchpoint-incentive/">
                            <span class="w-16px">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16" height="16" viewBox="0 0 16 16">
                                  <defs>
                                    <clipPath id="clip-path">
                                      <rect id="Rectangle_489" data-name="Rectangle 489" width="16" height="16" fill="#012169"/>
                                    </clipPath>
                                  </defs>
                                  <g id="Group_1974" data-name="Group 1974" clip-path="url(#clip-path)">
                                    <path id="Path_39111" data-name="Path 39111" d="M3.582,16H.263V5.313H3.582ZM1.924,3.853A1.927,1.927,0,1,1,3.845,1.926,1.924,1.924,0,0,1,1.924,3.853M16,16H12.685V10.8c0-1.239-.022-2.834-1.723-2.834-1.726,0-1.989,1.35-1.989,2.745V16H5.662V5.313H8.84V6.774h.045a3.483,3.483,0,0,1,3.137-1.726C15.379,5.048,16,7.26,16,10.139Z" fill="#012169"/>
                                  </g>
                                </svg>
                            </span> &nbsp;<span class="linked-in">LinkedIn</span>
                        </a>
                    </p>
                </div>
            </div>
            <div class="row d-block d-md-none">
                <div class="col-12 col-md-6">
                    <p class="mb-0">
                        {{new Date().getFullYear()}}  ©  Touchpoint Incentive
                    </p>
                    <p>
                        <a class="mr-4" href="">Polityka prywatności</a>
                        <a class="" href="">Pliki cookies</a>
                    </p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "FooterComponent"
}
</script>

<style lang="scss" scoped>
    .w-16px{
        width: 16px;
        text-align: center;
        display: inline-block;
    }
    .footer__logo{
        width: 152px;
        height: 35px;
    }
    .linked-in{
        position: relative;
        top:2px;
    }
</style>
