export const routes = [
    {
        path: '/',
        name: 'home',
        meta: { requiresAuth: false },
        component: () => import('../views/HomeView.vue')
    },
    {
        path : '*',
        name: 'error-404',
        component: () => import('../components/error/Error404.vue')
    },
    {
        path: '/realizacje/:slug',
        name: 'realizations',
        props: true,
        meta: { requiresAuth: false },
        component: () => import('../views/RealizationView.vue')
    },
]
