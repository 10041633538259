import Vue from 'vue'
import Vuex from 'vuex'
import VueDOMPurifyHTML from 'vue-dompurify-html'

Vue.use(Vuex)
Vue.use(VueDOMPurifyHTML)

import popup from './states/popup';
import map from './states/map';
import user from './states/user';

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
      popup,
      map,
      user
  }
})
