<template>
  <div id="app" class="app" :class="{'bg-app': backgroundImage}">
      <div>
          <router-link id="logo" class="logo" :to="{name: 'home'}">
              <img src="@/img/touchpoint_logo.svg" alt="Logo"/>
          </router-link>

          <transition>
              <transition name="move-down" mode="out-in">
                  <router-view/>
              </transition>
          </transition>
          <popup-component></popup-component>
      </div>
      <footer-component></footer-component>
  </div>
</template>

<script>

import PopupComponent from "@/components/modules/PopupComponent";
import FooterComponent from "@/components/modules/FooterComponent";
export default {
    name: 'App',
    components: {
        PopupComponent,
        FooterComponent
    },
    data() {
        return {
            backgroundImage: false,
            lastScrollTop: 0
        }
    },
    watch: {
        $route(to) {
            if (to.name !== 'login' && to.name !== 'loginRemind' && to.name !== 'resetPassword') {
                this.backgroundImage = true;
            } else {
                this.backgroundImage = false;
            }
        }
    },
    methods:{
        handleSCroll () {
            let logo = document.querySelector("#logo");
            var st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
            if (st > this.lastScrollTop && st>50){
                // downscroll code
                logo.classList.add('logo-hidden');
            } else {
                // upscroll code
                logo.classList.remove('logo-hidden');
            }
            this.lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
        },
    },
    created () {
        window.addEventListener('scroll', this.handleSCroll);
    },
    destroyed () {
        window.removeEventListener('scroll', this.handleSCroll);
    },
    computed: {
        isLoggedIn() {
            return this.$store.getters['user/isLoggedIn']
        }
    }
}
</script>

<style lang="scss">
    @import "src/styles/app";
</style>
