const messages = {
    "pl": {
        navigation:{
            about_us: 'O nas',
            realization: 'Realizacje',
            contact: 'Kontakt',
        },
        footer:{
            privacy_policy: 'Polityka prywatności',
            privacy_policy_url: 'assets/app/files/Ogolna_polityka_prywatnosci_i_ochrony_danych_osobowych_-_Touchpoint_Incentive.pdf',
            cookies: 'Pliki cookies',
            cookies_url: 'assets/app/files/Zasady_przetwarzania_danych_i_cookies.pdf',
            address: 'Adres',
            social_media: 'Social media',
        },
        cookies_box:{
            desc: 'Używamy plików cookies (tzw. „ciasteczka”) w celu optymalizacji korzystania ze stron internetowych, tworzenia statystyk. Możesz wyłączyć ten mechanizm w dowolnym momencie w ustawieniach przeglądarki.',
            btnText: 'Zamknij'
        },
        realization:{
            brand: 'Marka',
            participants: 'Uczestnicy',
            goal: 'Cel',
            more: 'Zobacz więcej',
            contact: 'Kontakt',
            see_gallery: 'Zobacz galerię',
            gallery: 'Galeria',
            map: {
                title: '<span class="color-main">Inne</span> realizacje',
                desc: 'Sprawdź dokąd jeszcze zabraliśmy naszych dotychczasowych klientów podczas ekscytujących wyjazdów incentive.'
            }
        },
        home:{
            top_banner:{
                title: 'Wyjazd to najlepsza<br><span class="color-main">nagroda na świecie!</span>',
                desc: 'Podróżowanie pomaga budować długotrwałe relacje z&nbsp;klientami, a&nbsp;pracowników motywuje bardziej niż jakiekolwiek pieniądze. Dlatego z&nbsp;wyjazdów incentive uczyniliśmy naszą specjalność, a&nbsp;z&nbsp;<span class="f-fieldwork-hum-bold">odkrywania marki</span> cel każdej podróży.',
                see_video: 'Zobacz wideo',
                view_our_work: 'Zobacz realizacje',
            },
            offer:{
                title: 'Działamy jak biuro podróży, <span class="color-main">myślimy jak agencja marketingowa</span>',
                desc_p1: 'Wyróżnia nas <span class="f-fieldwork-hum-bold">doświadczenie marketingowe</span>. A&nbsp;to znaczy, że sprawnie poruszamy się w&nbsp;świecie marki, rozumiemy grupy docelowe, biznesowe potrzeby oraz rolę doświadczeń w&nbsp;budowaniu relacji.',
                desc_p2: 'Zwiedziliśmy też kawał świata i&nbsp;doskonale <span class="f-fieldwork-hum-bold">wiemy, co zachwyca przyjezdnych</span> w&nbsp;Rio, Kapadocji, a&nbsp;nawet w&nbsp;Vopnafjörður. Wiemy, jak znaleźć idealne miejsce, w&nbsp;którym komunikacja marki przybierze na sile, a&nbsp;reklamowe slogany zmienią się w&nbsp;realne doświadczenia.',
                contact_us: 'Skontaktuj się z nami',
                right:{
                    title: 'Co zapewniamy?',
                    text_1: '<span class="f-fieldwork-hum-bold">Dedykowanego opiekuna</span> i&nbsp;najwyższy poziom obsługi',
                    text_2: '<span class="f-fieldwork-hum-bold">Zestaw materiałów</span>, które podsycają wyobraźnię na długo przed podróżą',
                    text_3: '<span class="f-fieldwork-hum-bold">Gwarancję turystyczną</span>',
                    text_4: '<span class="f-fieldwork-hum-bold">Motywację i&nbsp;więź z&nbsp;firmą</span> jakiej nie zapewni żadna premia',
                    text_5: '<span class="f-fieldwork-hum-bold">Scenariusz wyjazdu</span>, który wciąga do świata marki',
                    text_6: '<span class="f-fieldwork-hum-bold">Doskonałą znajomość</span> lokalnych atrakcji',
                    text_7: '<span class="f-fieldwork-hum-bold">Budowanie relacji</span> między marką a&nbsp;jej partnerami',
                    text_8: 'Pełnowymiarową <span class="f-fieldwork-hum-bold">komunikację wyjazdu</span>',
                }
            },
            map: {
                title: '<span class="color-main">Lecimy tam</span>, dokąd poniesie nas marka',
                desc: 'Nie mamy gotowej oferty. Stawiamy na doświadczenia nie do podrobienia, nieprzypadkowe miejsca i oryginalne scenariusze wyjazdów incentive. Zobacz nasze realizacje.',
            },
            contact:{
                title: 'Kontakt',
                desc: 'Oczekujesz czegoś więcej niż „wyjazdu pełnego atrakcji”? Napisz do nas!',
                email: {
                    label: 'Adres e-mail',
                    placeholder: 'Wpisz adres e-mail',
                    error: {
                        required: 'Adres e-mail wymagany',
                        email: 'Proszę podać poprawny adres e-mail',
                    }
                },
                message:{
                    label: 'Wiadomość',
                    placeholder: 'Twoja wiadomość',
                    error: {
                        required: 'Treść wymagana',
                        minLength: 'Pole message musi mieć przynajmniej {minLength} znaków',
                    }
                },
                rodo: 'Twoje dane podane w&nbsp;formularzu przetwarzamy w&nbsp;celu kontaktu z&nbsp;Tobą i&nbsp;udzielenia odpowiedzi na Twoją ' +
                    'wiadomość. Administratorem Twoich danych jest Touchpoint Incentive sp.&nbsp;z o.o., ul.&nbsp;Truskawiecka&nbsp;1, 60-478 Poznań (kontakt: a.singh@touchpoint-incentive.pl). Więcej informacji o&nbsp;regułach przetwarzania ' +
                    'Twoich danych na potrzeby kontaktu za pośrednictwem formularza kontaktowego oraz w&nbsp;związku z&nbsp;korzystaniem z&nbsp;tej strony internetowej znajdziesz w&nbsp;<a class="color-main" href="assets/app/files/Zasady_przetwarzania_danych_i_cookies.pdf" target="_blank">Zasadach przetwarzania danych osobowych</a>.',
                btnText: 'Wyślij wiadomość',
                sent: 'Twoja wiadomość została wysłana.',
                error: 'Niepoprawne dane'
            }
        }
    },
    "en": {
        navigation:{
            about_us: 'About&nbsp;us',
            realization: 'Realisations',
            contact: 'Contact&nbsp;us',
        },
        footer:{
            privacy_policy: 'Privacy policy',
            privacy_policy_url: 'assets/app/files/Ogolna_polityka_prywatnosci_i_ochrony_danych_osobowych_-_Touchpoint_Incentive_EN.pdf',
            cookies: 'Cookies',
            cookies_url: 'assets/app/files/Zasady_przetwarzania_danych_i_cookies_EN.pdf',
            address: 'Address',
            social_media: 'Social media',
        },
        cookies_box:{
            desc: 'We use cookies (so-called "cookies") to optimize the use of&nbsp;websites and to create statistics. You can disable this mechanism at&nbsp;any time in&nbsp;your browser settings.',
            btnText: 'Close'
        },
        realization:{
            brand: 'Brand',
            participants: 'Participants',
            goal: 'Goal',
            more: 'See more',
            contact: 'Contact&nbsp;us',
            see_gallery: 'View the gallery',
            gallery: 'Gallery',
            map: {
                title: '<span class="color-main">Other</span> realisations',
                desc: 'We’re putting an emphasis on experiences that cannot be replicated, locations that are no coincidence, and unique incentive travel scenarios.'
            }
        },
        home:{
            top_banner:{
                title: 'A trip is the best<br><span class="color-main">prize there is!</span>',
                desc: 'Travelling helps to build long-lasting relations with the clients and motivates the employees even better than any kind of money. Which is why we’ve made incentive travel our specialty and set <span class="f-fieldwork-hum-bold">brand discovery</span> as the main goal of&nbsp;each trip.',
                see_video: 'See video',
                view_our_work: 'View our work',
            },
            offer:{
                title: 'We operate like a&nbsp;travel agency, <span class="color-main">we think like a&nbsp;marketing agency</span>',
                desc_p1: 'Our distinguishing trait is our <span class="f-fieldwork-hum-bold">marketing experience</span>. This means that we know how to move freely in&nbsp;the&nbsp;brand’s world, understand the target groups, business needs and the meaning of&nbsp;the&nbsp;experiences in relationship building.',
                desc_p2: 'We’ve travelled all around the world as well, and <span class="f-fieldwork-hum-bold">we know exactly what amazes the tourists</span> in&nbsp;Rio, Cappadocia, and even in the Vopnafjörður. We know how to find the perfect spot, in which the brand’s communication is strengthened, and the marketing slogans change into real experiences.',
                contact_us: 'Contact us',
                right:{
                    title: 'What do we provide?',
                    text_1: '<span class="f-fieldwork-hum-bold">Dedicated attendant</span> and the highest quality of&nbsp;service',
                    text_2: '<span class="f-fieldwork-hum-bold">A set of&nbsp;materials</span>, to stimulate the imagination long before the&nbsp;trip',
                    text_3: '<span class="f-fieldwork-hum-bold">Travel insurance guarantee</span>',
                    text_4: '<span class="f-fieldwork-hum-bold">Motivation and bond with the company</span> that no other kind of bonus can provide',
                    text_5: '<span class="f-fieldwork-hum-bold">A scenario for the&nbsp;trip</span>, to pull the participant into the brand’s world',
                    text_6: '<span class="f-fieldwork-hum-bold">Extensive knowledge</span> of&nbsp;the&nbsp;local attractions',
                    text_7: '<span class="f-fieldwork-hum-bold">Building a&nbsp;relationship</span> between the brand and it’s partners',
                    text_8: 'Comprehensive <span class="f-fieldwork-hum-bold">communication of&nbsp;the&nbsp;trip</span>',
                }
            },
            map: {
                title: '<span class="color-main">We fly</span>, wherever the&nbsp;brand takes&nbsp;us',
                desc: 'We haven’t got any fixed offer. We’re putting an&nbsp;emphasis on&nbsp;experiences that cannot be replicated, locations that are no coincidence, and unique incentive travel scenarios. Check out our realisations.',
            },
            contact:{
                title: 'Contact&nbsp;us',
                desc: 'Do you expect something more than just a&nbsp;“excitement-filled trip”? Contact&nbsp;us!',
                email: {
                    label: 'E-mail address',
                    placeholder: 'Your email address here',
                    error: {
                        required: 'E-mail address required',
                        email: 'Please enter a&nbsp;valid email address',
                    }
                },
                message:{
                    label: 'Message',
                    placeholder: 'Your message',
                    error: {
                        required: 'Content required',
                        minLength: 'The message field must be at least {minLength} characters long',
                    }
                },
                rodo: 'We process your data provided in the form in order to contact you and respond to your ' +
                    'message. The Controller of your data is Touchpoint Incentive sp. z o.o., ul. Truskawiecka 1, ' +
                    '60-478 Poznań (contact person: a.singh@touchpoint-incentive.pl). You can find more ' +
                    'information about the rules of processing your data for the purposes of contacting you via the ' +
                    'contact form and in connection with the use of this website in the ' +
                    '<a class="color-main" href="assets/app/files/Zasady_przetwarzania_danych_i_cookies_EN.pdf" target="_blank">Personal data processing rules</a>.',
                btnText: 'Send the message',
                sent: 'Your message has been sent.',
                error: 'Incorrect data'
            }
        }
    },
};

export default messages;
