import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/index';
import Vuelidate from 'vuelidate';
import VueI18n from 'vue-i18n';
import VueDOMPurifyHTML from "vue-dompurify-html";
import VueScrollactive from 'vue-scrollactive';
import messages from "@/i18n/messages";
import VueGtag from "vue-gtag"
import VueLazyLoadVideo from 'vue-lazyload-video'
Vue.use(Vuelidate);
Vue.use(VueDOMPurifyHTML);
Vue.use(VueI18n);
Vue.use(VueScrollactive);
Vue.use(VueLazyLoadVideo);

import 'bootstrap/dist/js/bootstrap.min';

Vue.config.productionTip = false

import api from './helpers/api';
window.api = api;

Vue.use(VueGtag, {
    config: { id: "G-9CP15L2VSC" },
    //deferScriptLoad: true,
});

const i18n = new VueI18n({
    locale: localStorage.getItem("locale") ? localStorage.getItem("locale") : 'pl',
    fallbackLocale: 'pl',
    messages,
})


const app = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
});

app.$mount('#app');
