import Vue from 'vue'
import VueRouter from 'vue-router'
import {routes} from "@/router/routes";
import store from "@/store";

Vue.use(VueRouter)


const router = new VueRouter({
    mode: 'history',
    base: '/',
    saveScrollPosition: false,
    scrollBehavior (to, from, savedPosition) {
        if (to.hash) {
            return {
                selector: to.hash,
            }
        } else if(savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    },
    routes
})

router.beforeEach(async (to, from, next) => {
    if (to.meta.requiresAuth) {
        if (store.getters['user/isLoggedIn']) {
            await store.dispatch('user/getUser');
        }
        store.getters['user/isLoggedIn'] ? next() : next({name: 'login'});
    } else if ((to.name === 'login' || to.name === 'login-remind' || to.name === 'register') && store.getters['user/isLoggedIn']) {
        next({name: 'home'})
    } else {
        next();
    }
})

export default router
